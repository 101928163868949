
import 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0/dist/cookieconsent.umd.js';

let loadScript = false;

// Function to listen for consent
const listenForConsent = () => {
	if (window._ccRun) return;
  
	function gtag(){window.dataLayer.push(arguments);}
  
	// Setting initial consent options
	gtag('consent', 'default', {
	  'ad_storage': 'denied',
	  'ad_user_data': 'denied',
	  'ad_personalization': 'denied',
	  'analytics_storage': 'granted',
	  'functionality_storage': 'granted',
	  'personalization_storage': 'granted',
	  'security_storage': 'granted',
	});
  
	loadScript = true;
  
	// Function to update consent based on user's choice
	const updateGtagConsent = () => {
	  gtag('consent', 'update', {
		'ad_storage': CookieConsent.acceptedCategory('ads') ? 'granted' : 'denied',
		'ad_user_data': CookieConsent.acceptedCategory('ads') ? 'granted' : 'denied',
		'ad_personalization': CookieConsent.acceptedCategory('ads') ? 'granted' : 'denied',
		'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
		'functionality_storage': CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
		'personalization_storage': CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
		'security_storage': 'granted', //necessary
	  });
	};
  
	// Event listeners for consent changes
	window.addEventListener('cc:onConsent', () => {
	  updateGtagConsent();
	});
  
	window.addEventListener('cc:onChange', () => {
	  updateGtagConsent();
	});
  };

  listenForConsent();

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({

// root: 'body',
// autoShow: true,
// disablePageInteraction: true,
// hideFromBots: true,
// mode: 'opt-in',
// revision: 0,

cookie: {
	name: 'cc_cookie',
	// domain: location.hostname,
	// path: '/',
	// sameSite: "Lax",
	// expiresAfterDays: 365,
},

// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
guiOptions: {
	consentModal: {
		layout: 'box',
		position: 'bottom center',
		equalWeightButtons: false,
		flipButtons: false
	},
	preferencesModal: {
		layout: 'box',
		equalWeightButtons: false,
		flipButtons: false
	}
}, 

onFirstConsent: ({cookie}) => {
	//console.log('onFirstConsent fired',cookie);
},

onConsent: ({cookie}) => {
	//console.log('onConsent fired!', cookie)
},

onChange: ({changedCategories, changedServices}) => {
	//console.log('onChange fired!', changedCategories, changedServices);
},

onModalReady: ({modalName}) => {
	//console.log('ready:', modalName);
},

onModalShow: ({modalName}) => {
	//console.log('visible:', modalName);
},

onModalHide: ({modalName}) => {
	//console.log('hidden:', modalName);
},

categories: {
	necessary: {
		enabled: true,  // this category is enabled by default
		readOnly: true  // this category cannot be disabled
	},
	analytics: {
		enabled: true,  // this category is enabled by default
		readOnly: true,  // this category cannot be disabled
		autoClear: {
			cookies: [
				{
					name: /^_ga/,   // regex: match all cookies starting with '_ga'
				},
				{
					name: '_gid',   // string: exact cookie name
				}
			]
		},

		// https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
		services: {
			ga: {
				label: 'Google Analytics',
				onAccept: () => {},
				onReject: () => {}
			},
			youtube: {
				label: 'Youtube Embed',
				onAccept: () => {},
				onReject: () => {}
			},
		}
	},
	ads: {
		enabled: true  // this category is enabled by default
	}
},

language: {
	default: 'nl',
	translations: {
		nl: {
			consentModal: {
				title: '',
				description: 'Onze website maakt gebruik van cookies om jou de beste ervaring te geven, en om relevante informatie en aanbiedingen te tonen. <a href="/privacy-verklaring" class="cc-link">Lees meer</a>',
				acceptAllBtn: 'Accepteer',
				acceptNecessaryBtn: '',
				showPreferencesBtn: 'Instellingen',
				// closeIconLabel: 'Reject all and close modal',
				footer: '',
			},
			preferencesModal: {
				title: 'Cookie voorkeuren',
				acceptAllBtn: 'Accepteer alle',
				acceptNecessaryBtn: 'Weiger alle',
				savePreferencesBtn: 'Instellingen opslaan',
				closeIconLabel: 'Sluiten',
				serviceCounterLabel: 'Dienst|Diensten',
				sections: [
					{
						title: 'Jouw privacy keuzes',
						description: `Je kan hier je voorkeuren kenbaar maken met betrekking tot de verwerking van je persoonlijke gegevens. Je kan de gemaakte keuzes op elk moment bekijken en wijzigen. Om je toestemming voor te weigeren, schakel je de schakelaars uit of gebruik je de knop ‘Weiger alle’ en bevestig je dat je jouw keuzes keuzes wilt opslaan.`,
					},
					{
						title: 'Strikt noodzakelijke cookies',
						description: 'Deze cookies zijn essentieel voor de werking van de website. Zonder deze cookies kan je de website niet gebruiken.',

						//this field will generate a toggle linked to the 'necessary' category
						linkedCategory: 'necessary'
					},
					{
						title: 'Performance en Analytische cookies',
						description: 'Deze cookies zorgen ervoor dat de website jouw keuzes onthoudt',
						linkedCategory: 'analytics',
						cookieTable: {
							caption: 'Cookie table',
							headers: {
								name: 'Cookie',
								domain: 'Domein',
								desc: 'Omschrijving'
							},
							body: [
								{
									name: '_ga',
									domain: location.hostname,
									desc: 'Description 1',
								},
								{
									name: '_gid',
									domain: location.hostname,
									desc: 'Description 2',
								}
							]
						}
					},
					{
						title: 'Advertising en Targeting cookies',
						description: 'Deze cookies verzamelen informatie over hoe je de website gebruikt, welke pagina\'s je hebt bekeken en welke links je op hebt geklikt. Al deze data is geannonimiseerd en kan niet gebruikt worden om je te identificeren. Het zorgt ervoor dat we je relevante advertenties kunnen laten zien op externe websites.',
						linkedCategory: 'ads',
					},
					{
						title: 'Meer informatie',
						description: 'Voor vragen over ons cookiebeleid en jouw keuzes, neem dan <a class="cc-link" href="/contact">contact op</a>.'
					}
				]
			}
		}
	}
}
});